
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  onBeforeUnmount,
} from "vue"
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css"
import GC from "@grapecity/spread-sheets"
import {
  initSpread,
  usetableHeight,
  setHeader,
  bindDataTable,
  saveData,
} from "./utils"
import blobDownload from "@/utils/blobDownload"
import { ImPACTMSRPProps, SearchParams } from "./types"
import {
  getIMPACTMSRP,
  saveIMPACTMSRP,
  getMakeYearList,
} from "@/API/sysParam"
import { message } from "ant-design-vue"
import moment from 'moment';
import { getMonth } from '@/utils'

export default defineComponent({
  name: "",
  setup() {
    const currentMonth = getMonth();
    const searchParams = reactive<SearchParams>({
      yearValue: 0,
      makeValue: "",
    })

    // 获取表格的高度
    const { tableHeight } = usetableHeight("#ssHost", "#ssHostvp")

    // 设置初始化数据变量
    const dataSource = reactive<ImPACTMSRPProps>({
      data: [],
      yearList: [],
      makeList: [],
    })

    // 根据FC Code排序
    const byFCodeSort = () => {
      dataSource.data.sort((item1, item2) => {
        if (item1.impactCode < item2.impactCode) {
            return -1;
        } else if (item1.impactCode > item2.impactCode) {
            return 1;
        } else {
            return 0;
        }
      })
    }

    // 初始化Spread
    // sheet当前表格的初始化
    let workbook: GC.Spread.Sheets.Workbook
    const ssHotTopInit = () => {
      workbook && workbook.destroy()
      const spread = new GC.Spread.Sheets.Workbook(
        document.getElementById("ssHost") as HTMLElement
      )
      workbook = spread
      const currentYear = +moment().format('YYYY')
      const allYear = currentYear < searchParams.yearValue ? 0 : currentMonth

      setHeader(workbook, allYear)

      initSpread(workbook, allYear)
      byFCodeSort()
      bindDataTable(workbook, dataSource.data, allYear)
    }

    // 获取数据
    const initIMPACTMSRP = () => {
      const params = {
        make: searchParams.makeValue,
        year: searchParams.yearValue || 0,
      }

      getIMPACTMSRP({ params }).then((res) => {
        Object.assign(dataSource, res)
        dataSource.data = res.data
        // 查询条件初始化
        // searchParams.yearValue =
        //   searchParams.yearValue ||
        //   (dataSource.yearList.length && dataSource.yearList[0])
        // searchParams.makeValue = searchParams.makeValue || dataSource.makeList[0]

        ssHotTopInit()
      })
    }

    // 获取make year list
    const initYearMakeList = () => {
      getMakeYearList().then(res => {
        dataSource.makeList = res.make
        dataSource.yearList = res.year
        // 查询条件初始化
        searchParams.yearValue = dataSource.yearList[0]
        searchParams.makeValue = dataSource.makeList[0]
        initIMPACTMSRP()
      })
    }

    const isEmpty = (data: any) => {
        let isSave = true
        data.forEach((item: any)  => {
          if (!item.brand || !item.typeClass) {
            isSave = false
          }
        });
        return isSave
    }

    const saveFn = () => {
      if (saveData.length === 0){
        return
      } else {
        if (!isEmpty(dataSource.data)) {
          message.error('Brand和Type Class不能为空')
          return
        }
      }
      saveIMPACTMSRP(saveData).then((res) => {
        message.success("Save successfully!")
        initIMPACTMSRP()
      })
    }

    const doSearch = () => {
      initIMPACTMSRP()
    }

    // 导出表格
    const handlerExport = () => {
      if (
        searchParams.yearValue &&
        searchParams.makeValue
      ) {
        const config = {
          url: "/vapi/msrp/impact/export",
          params: {
            make: searchParams.makeValue,
            year: searchParams.yearValue || 0,
          },
        }
        blobDownload(config)
      } else {
        message.error("Please select parameters")
      }
    }

    onMounted(() => {
      // initIMPACTMSRP()
      initYearMakeList()
      // ssHotTopInit()
    })

    onBeforeUnmount(() => {
      workbook.destroy()
    })

    return {
      // table的高度
      tableHeight,
      doSearch,
      saveFn,
      // 导出文件
      handlerExport,

      ...toRefs(dataSource),
      searchParams,
    }
  },
})
