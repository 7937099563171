import GC from '@grapecity/spread-sheets'
import { reactive, toRefs, onMounted,  } from 'vue';
import { HeaderData, ImPACTMSRPData, MsrpData } from './types'
import { getMonth } from '@/utils'
import { message } from 'ant-design-vue'
interface TablePosition {
    tableHeight: number;
}

interface SaveData {
    id: string;
    overrideValue: number;
}

// 当前月
const currentMonth = getMonth();

let tableData: ImPACTMSRPData[];

export let saveData: SaveData[] = [];

const columnsWidth = {
    'Brand': 75,
    'Type Class': 90,
    'FC Code': 90,
    'FC Name': 180,
}
// 表格确定列数量
const baseColCount = 4;

const baseHeader = [{
    displayName: 'Brand',
},{
    displayName: 'Type Class',
},{
    displayName: 'FC Code',
},{
    displayName: 'FC Name',
}];

const monthHeader = [{
    displayName: 'Jan',
},{
    displayName: 'Feb',
},{
    displayName: 'Mar',
},{
    displayName: 'Apr',
},{
    displayName: 'May',
},{
    displayName: 'Jun',
},{
    displayName: 'Jul',
},{
    displayName: 'Aug',
},{
    displayName: 'Sep',
},{
    displayName: 'Oct',
},{
    displayName: 'Nov',
},{
    displayName: 'Dec',
}]

const baseKeys = ['brand', 'typeClass', 'impactCode', 'impactName'];

const monthKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

const getFirstHeader = (allYear: number) => {
    const pastMonth = monthHeader.slice(0, allYear)
    const futureMonth = monthHeader.slice(allYear)
    
    const doubleFutureMonth = futureMonth.map((item) => {
        return [item, item]
    }).flat()

    return baseHeader.concat(pastMonth, doubleFutureMonth)    
}

const getSecondHeader = (allYear: number) => {
    
    const pastMonth = monthHeader.slice(0, allYear).map((item) => {
        return {
            displayName: ''
        }
    })

    const futureMonth = monthHeader.slice(allYear)
    
    const doubleFutureMonth = futureMonth.map(() => {
        return [{
            displayName: 'MSRP',
        },{
            displayName: 'Override',
        }]
    }).flat()

    return baseHeader.concat(pastMonth, doubleFutureMonth)    
}

const getDataKeys = (allYear: number) => {
    const pastMonthKeys = monthKeys.slice(0, allYear)
    const futureMonth = monthKeys.slice(allYear)
    
    const doubleFutureMonthKeys = futureMonth.map((item) => {
        return [item, item]
    }).flat()

    return baseKeys.concat(pastMonthKeys, doubleFutureMonthKeys)    
}

// const firstHeader = getFirstHeader()
// const secondHeader = getSecondHeader()
// const dataKeys: string[] = getDataKeys()

// 设置表格的头部，可以不占用单元格
// const header: HeaderData[][] = [
//     firstHeader, secondHeader
// ]


// 设置列宽度
const setColumnWidth = (sheet: GC.Spread.Sheets.Worksheet, secondHeader: {displayName: string}[]) => {
    secondHeader.forEach((item, index) => {
        if (!columnsWidth[item.displayName]) {
            sheet.setColumnWidth(index, 120)
        } else {
            sheet.setColumnWidth(index, columnsWidth[item.displayName])
        }
    })
}

const setFilterButtonVisible = (rowFilter: GC.Spread.Sheets.Filter.HideRowFilter, dataKeys: string[]) => {
    for (let keyInd = 0; keyInd < dataKeys.length; keyInd++) { 
        if (keyInd > baseColCount || keyInd === baseColCount)
        rowFilter.filterButtonVisible(keyInd, false)
    }
}

// 设置表头
export const setHeader = (spread: GC.Spread.Sheets.Workbook, allYear: number ) => {
    const sheet = spread.getActiveSheet();
    //挂起
    sheet.suspendPaint();
    //设置header行数为2行
    sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);
    // 换行
    const row = sheet.getRange(1, -1, 1, -1, GC.Spread.Sheets.SheetArea.colHeader);
    row.wordWrap(true);

    const header: HeaderData[][] = [
        getFirstHeader(allYear), getSecondHeader(allYear)
    ] 
    sheet.setColumnCount(getSecondHeader(allYear).length, GC.Spread.Sheets.SheetArea.viewport)

    setColumnWidth(sheet, getSecondHeader(allYear));
    const range = new GC.Spread.Sheets.Range(-1, 0, -1, getSecondHeader(allYear).length);
    const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    sheet.rowFilter(rowFilter);
    rowFilter.filterDialogVisibleInfo({
        sortByColor: false,
    })
    const dataKeys: string[] = getDataKeys(allYear)
    setFilterButtonVisible(rowFilter, dataKeys)
    //set header 设置头的样式
    for (let row = 0; row < header.length; row++) {
        for (let cell = 0; cell < header[row].length; cell++) {
            sheet.setValue(row, cell, header[row][cell].displayName, GC.Spread.Sheets.SheetArea.colHeader);
            sheet.getCell(row, cell, GC.Spread.Sheets.SheetArea.colHeader).backColor('#bfbfbf')
        }
        // sheet.setRowHeight(row, 40, GC.Spread.Sheets.SheetArea.colHeader);
    }
    //合并行 
    for (let i=0; i<baseColCount; i++) {
        sheet.addSpan(0, i, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);
    }

    for (let index = 0; index < header[0].length; index++) {
        if (index > baseColCount - 1) {
            sheet.getRange(-1, index, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
            // sheet.getRange(1, index, 1, 1, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
        } else {
            sheet.getRange(-1, index, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.left)
            // sheet.getRange(1, index, 1, 1, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.left)
        }
    }
    sheet.getRange(-1, 0, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.center)
    sheet.getRange(-1, 1, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.center)

    // 自动合并
    const ranges = new GC.Spread.Sheets.Range(-1, -1, -1, -1);
    sheet.autoMerge(ranges, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.row, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.colHeader);
    sheet.autoMerge(ranges, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.column, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.rowHeader);
    //重绘
    sheet.resumePaint();
}



// 绑定数据
export const bindDataTable = (spread: GC.Spread.Sheets.Workbook, data: ImPACTMSRPData[], allYear: number) => {
    tableData = data;
    saveData = [];
    // 挂起
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    const spreadNS = GC.Spread.Sheets;
    // 设置多少行和列
    sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport)
    // sheet.setColumnCount(secondHeader.length, GC.Spread.Sheets.SheetArea.viewport)
    const canEdit = new spreadNS.Style();
    canEdit.locked = false;

    // const range = new GC.Spread.Sheets.Range(-1, 0, -1, secondHeader.length);
    // const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    // sheet.rowFilter(rowFilter);
    // rowFilter.filterDialogVisibleInfo({
    //     sortByColor: false,
    // })
    // setFilterButtonVisible(rowFilter)

    // 每次绑定数据时清除表单中的数据、样式
    sheet.clear(0,0,sheet.getRowCount(),sheet.getColumnCount(), GC.Spread.Sheets.SheetArea.viewport, GC.Spread.Sheets.StorageType.data)
    sheet.clear(0,0,sheet.getRowCount(),sheet.getColumnCount(), GC.Spread.Sheets.SheetArea.viewport, GC.Spread.Sheets.StorageType.style)
    const dataKeys = getDataKeys(allYear)
    // 设置表单数据和样式
    data.forEach((item, rowIndex) => {
        let isMsrp = true;
        for (let keyInd = 0; keyInd<dataKeys.length; keyInd++) {
            if (keyInd < baseColCount) {
                // 当前月份之前的月份对应第二行有一列数据
                console.log(keyInd, dataKeys, 'dataKeys')
                sheet.setValue(rowIndex, keyInd, item && item[dataKeys[keyInd]], GC.Spread.Sheets.SheetArea.viewport);
                sheet.getCell(rowIndex, keyInd).backColor('#f0f0f0')
            } else if (keyInd < allYear + baseColCount) {
                // 当前月份之前的月份对应第二行有一列数据
                sheet.setValue(rowIndex, keyInd, item && item[dataKeys[keyInd]], GC.Spread.Sheets.SheetArea.viewport);
                // 数据格式设置
                // sheet.setFormatter(rowIndex, keyInd, "#,##0.00", spreadNS.SheetArea.viewport);
                sheet.setFormatter(rowIndex, keyInd, "#,##", spreadNS.SheetArea.viewport);
                sheet.getCell(rowIndex, keyInd).backColor('#f0f0f0')

            } else {
                // 当前月份以及之后月份对应第二行有两列数据
                if (isMsrp) {
                    console.log(keyInd, dataKeys, 'msrp')
                    const msrp = item && item[dataKeys[keyInd]] && (item[dataKeys[keyInd]] as MsrpData).msrp;
                    sheet.setValue(rowIndex, keyInd, msrp, GC.Spread.Sheets.SheetArea.viewport);
                    sheet.getCell(rowIndex, keyInd).backColor('#f0f0f0')

                    // 数据格式设置
                    // sheet.setFormatter(rowIndex, keyInd, "#,##0.00", spreadNS.SheetArea.viewport);
                    sheet.setFormatter(rowIndex, keyInd, "#,##", spreadNS.SheetArea.viewport);
                    isMsrp = false;
                } else {    
                    console.log(keyInd, dataKeys, 'override')               
                    const override = item && item[dataKeys[keyInd]] && (item[dataKeys[keyInd]] as MsrpData).override
                    sheet.setValue(rowIndex, keyInd, override, GC.Spread.Sheets.SheetArea.viewport);

                    // 设置单元格可编辑
                    sheet.setStyle(rowIndex, keyInd, canEdit);

                    // 数据格式设置
                    // sheet.setFormatter(rowIndex, keyInd, "#,##0.00", spreadNS.SheetArea.viewport);
                    sheet.setFormatter(rowIndex, keyInd, "#,##", spreadNS.SheetArea.viewport);
               
                    isMsrp = true; 
                    sheet.getCell(rowIndex, keyInd).setBorder( new spreadNS.LineBorder( '#d4d4d4' , spreadNS.LineStyle.thin),
                        { all: true }
                    ); 
                }   
                           
            }
        }  
        // sheet.setRowHeight(rowIndex, 40, GC.Spread.Sheets.SheetArea.viewport);
        sheet.autoFitRow(rowIndex);

        if (!item.brand || !item.typeClass) {
            sheet.getRange(rowIndex, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#ffff00')
        }
    })
       
    // 设置冻结列
    sheet.frozenColumnCount(baseColCount)

    // 设置冻结行 表头
    // sheet.frozenRowCount(2)

    // 设置过滤的
    // setFilter(frozenIndex, table, data[0])

    spread.resumePaint();
}




export const initSpread = (spread: GC.Spread.Sheets.Workbook, allYear: number) => {
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    const spreadNS = GC.Spread.Sheets;

    // setColumnWidth(sheet)
    // 设置单元格宽高
    // sheet.defaults.rowHeight = 20;

    // 设置列数
    // sheet.setColumnCount(firstHeader.length);

    // 设置隐藏列头
    sheet.options.rowHeaderVisible = false
    // sheet.options.colHeaderVisible = false
    // sheet.options.clipBoardOptions = spreadNS.ClipboardPasteOptions.values;

    // sheet是否显示x
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    
    //填充铺满整个canvas
    spread.options.scrollbarMaxAlign = true;
    spread.options.scrollByPixel = true;
    //禁止缩放
    spread.options.allowUserZoom = false;

    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true;
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();
    //修改并设置表的默认样式locked为false.
    sheetStyle.locked = true;
    //默认对其方式
    sheetStyle.hAlign = spreadNS.HorizontalAlign.center;
    sheetStyle.vAlign = spreadNS.VerticalAlign.center;
    sheet.setDefaultStyle(sheetStyle);

    /* const fontColor = new spreadNS.Style();
    fontColor.foreColor = 'red'
    fontColor.locked = false; */
    const dataKeys = getDataKeys(allYear)
    const secondHeader = getSecondHeader(allYear)
    const setData = (args: any, type: boolean) => {
        let codeValue = '';
        if (type) {
            codeValue = sheet.getCell(args.row, args.col).value()
        } else {
            codeValue = args.newValue
        }
        if (isNaN(Number(args.newValue)) && !type) {
            message.error('请输入数字类型');
            sheet.resumeEvent();
            return;
        }
        const row = args.row;
        const col = args.col;
        const key = dataKeys[col]
        const rowData = tableData[row];
        const cellData = rowData[key] as MsrpData;
        let isChange = true;
        let text: any = new Number(codeValue)
        const numNext = text.valueOf()
        
        if (typeof(numNext) === 'number') {
            if (numNext === 0 || isNaN(numNext)) {
                text = null; 
            } else {
                text = text.toFixed(2).toString()
            }
        } else {
            text = null;   
        } 

        if (cellData.override === text) {
            // override数据没发生个变化
            isChange = false;
        }

        // override数据没发生个变化，对应后面月份数据自动改变
        for (let i=col; i<secondHeader.length;) {
            const cellData = rowData[dataKeys[i]] as MsrpData;

            const itemData = {
                id: cellData.id ,
                overrideValue: text
            };

            // 判断当前数据这条数据，如果存在替换原有值，不存在存入saveData
            for (const item of saveData) {
                if (item.id === cellData.id) {
                    item.id = itemData.id;
                    item.overrideValue = itemData.overrideValue;
                    break;
                }
            }

            const ind = saveData.findIndex(item => {
                return item.id === cellData.id
            })
            // 当前数据不存在于saveData则存入saveData 或 和原数据不相等
            if (ind === -1 && isChange) {
                saveData.push(itemData)
            } else if (ind !== -1 && isChange){
                // 当前数据存在于saveData且变化则修改该数据
                saveData[ind].overrideValue = itemData.overrideValue;
            } else if (ind !== -1 && isChange === false){
                // 在saveData中，但是和原数据相比未变化,删除
                saveData.splice(ind, 1)
            } else {
                // 没修改,也不存在于saveData中(相当于从来没编辑过)
                return
            }
            
            // 当前行对应修改月份后面月份的Override都变
            sheet.setValue(row, i, text, GC.Spread.Sheets.SheetArea.viewport);
           
            // 数据格式设置
            // sheet.setFormatter(row, i, "#,##0.00", spreadNS.SheetArea.viewport);
            sheet.setFormatter(row, i, "#,##", spreadNS.SheetArea.viewport);
            
            i += 2;
        }
    }

    spread.bind(spreadNS.Events.ValueChanged, function (e: any, args: any) {
        setData(args, false )
    });

    let isCopy = false;

    sheet.bind(GC.Spread.Sheets.Events.ClipboardPasting, function (e: any, info: any) {
        isCopy = true;
    });

    sheet.bind(spreadNS.Events.RangeChanged, (e: any, info: any) => {
        // 只处理粘贴进来的
        if (isCopy === true) {
            info.changedCells.forEach((item: any) => {
                setData(item, true )
            })
            isCopy = false
        }   
    }) 

    const option = {
        allowSelectLockedCells: true,
        allowSelectUnlockedCells: true,
        allowFilter: true, // 允许过滤
        allowSort: true,
        allowResizeRows: false,
        allowResizeColumns: true,
        allowEditObjects: false,
        allowDragInsertRows: false,
        allowDragInsertColumns: false,
        allowInsertRows: false,
        allowInsertColumns: false,
        allowDeleteRows: false,
        allowDeleteColumns: false
    };
    sheet.options.protectionOptions = option;

    spread.resumePaint();
}

// 计算表格的高度
export const usetableHeight = (id: string, idTop?: string) => {
    const tablePosition = reactive<TablePosition>({tableHeight: 200});
    const resizeHandler = (): void => {
        try {
            // 获取body的高度
            const clientHeight: number = document.body.clientHeight;
            const tableOffsetTop: number = (document.querySelector(id) as HTMLDivElement).getBoundingClientRect().top;
            
            tablePosition.tableHeight = clientHeight - tableOffsetTop - 15;
            (document.querySelector(id) as HTMLDivElement).style.height = tablePosition.tableHeight + 'px'
        } catch (e) {
            console.log(e);
        }
    }
    onMounted(() => {
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
    })

    return toRefs(tablePosition);
}